<template>
  <div>
    <lms-default-loader v-if="isLoading" />

    <div v-else>
      <v-card elevation="0">
        <v-card-text>
          <v-btn-toggle v-model="user.lang" @change="onChangeLang">
            <v-btn value="es">{{ $t('loginPage.langs.spanish') }}</v-btn>
            <v-btn value="en">{{ $t('loginPage.langs.english') }}</v-btn>
            <v-btn value="pt">{{ $t('loginPage.langs.portuguese') }}</v-btn>
          </v-btn-toggle>
        </v-card-text>
      </v-card>
      <lms-btn-holder>
        <v-btn large depressed color="primary" @click="save">
          {{ $t('labels.save') }}
        </v-btn>
        <v-btn large depressed @click="back">
          {{ $t('labels.cancel') }}
        </v-btn>
      </lms-btn-holder>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    refresh: 0,
  }),
  computed: {
    dataValues() {
      return this.$store.state.setting.dataValues;
    },
    user() {
      return this.$store.getters['user/current'];
    },
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      this.$store.state.user.current._id = this.$store.getters['auth/user'].id;
      await this.$store.dispatch('user/getData', ['lang']);
      this.$store.commit('system/loading', false);
    },
    onChangeLang(value) {
      this.user.lang = value;
      this.$i18n.locale = this.user.lang;
      this.$vuetify.lang.current = this.user.lang;
    },
    async save() {
      await this.$store.dispatch('user/saveData', ['lang']);
      this.$store.commit('snackbar/show', {
        content: this.$t('alerts.profile.languageUpdade.success'),
        type: 'success',
      });
      this.back();
    },
    async back() {
      this.$router.push({ name: 'profile-view' });
    },
  },
};
</script>
